<template>
  <div class="list">
    <list-search @onSearch="onSearch" @clearSearch="clearSearch" :childData="childData"></list-search>
    <div class="evaluation-box">
      <div class="tabStart">
        <table-tab :TabData="navData" @tabSwitch="onSwitch" defaultData="1"></table-tab>
        <el-button class="textBtn" @click="handleImport" type="text">导入关键事项</el-button>
      </div>
      <div>
        <!-- 保修期定义 -->
        <warranty ref="serchData" @queryData="queryData" v-if="tableShow === 1"></warranty>
        <!-- 关键事项录入 -->
        <key-issues ref="serchData" @queryData="queryData" v-if="tableShow === 2"></key-issues>
      </div>
    </div>
  </div>
</template>

<script>
import Component from '@/assets/js/components.js'
import { SearchList, SearchData } from './js/setting.js'
import { request } from '@/assets/js/http.js'
// import qs from 'qs'
// import dayjs from 'dayjs'
export default {
  components: Component.components,
  name: 'ReworkInput',
  data () {
    var pageSize = parseInt((document.documentElement.clientHeight - 330) / 42)
    if (pageSize < 10) {
      pageSize = 10
    }
    return {
      queryData: '',
      tableShow: 1,
      selectTabData: [],
      childData: { searchList: SearchList, searchData: SearchData },
      navData: [
        {
          label: '保修期定义',
          name: '1'
        },
        {
          label: '关键事项录入',
          name: '2'
        }
      ]
    }
  },
  created () {
    this.supplierList()
  },

  methods: {
    // 状态切换查询
    onSwitch: function (data) {
      if (data.props.label === '保修期定义') {
        this.tableShow = 1
      }
      if (data.props.label === '关键事项录入') {
        this.tableShow = 2
      }
    },

    // 数据列表
    myBidData () {

    },

    // 获取公司数据列表
    supplierList () {
      var newOption = []
      request('/api/supplier/basicData/getForSelect', 'POST').then((res) => {
        if (res.code === '200') {
          res.data.forEach(item => {
            newOption.push({
              label: `${item.erpCode}-${item.name}`,
              value: `${item.erpCode}-${item.name}`
            })
          })
          this.childData.searchList[0].option = newOption
        }
      })
    },

    // 导入关键事项
    handleImport () {
      this.$router.push({
        path: '/MainData/ImportData'
      })
    },

    // 查询
    onSearch (data) {
      this.$refs.serchData.getDataList(data)
    },
    // 清空
    clearSearch (data) {
      this.$refs.serchData.getDataList(data)
    }
  }
}
</script>

<style scoped lang="scss">
.list{
  .exportData{
    border-bottom: 1px solid #dddcdc;
    padding: 8px 0px 5px;
  }
  .evaluation-box{
    padding: 0 30px;
    :deep(.el-tabs__header){
      margin: 0;
    }
    .tabStart{
      position: relative;
      .textBtn{
        font-size: 16px;
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }
  .elrowflx{
    margin-bottom: 20px;
    :deep(.el-col){
      margin-bottom: 8px;
      line-height: 34px;
    }
  }
}
</style>
